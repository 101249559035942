/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		Rally for a Cause
	Date:		31st October 2018
	Author:		Edge Marketing Solutions

===========================================================================*/
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #2b2e34;
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 2rem 0;
  font-family: "Francois One", sans-serif;
}

h1 {
  margin: 0 0 2rem 0;
  color: #2b2e34;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #e46723;
}

a:hover {
  color: #00a79d;
}

.text-orange {
  color: #e46723;
}

.btn {
  border-radius: 0;
  font-family: "Francois One", sans-serif;
  white-space: normal;
}

.btn-primary {
  background-color: #e46723;
  border: none;
}

.btn-primary:hover {
  background-color: #00a79d;
  border: none;
}

.btn-secondary {
  background-color: #2b2e34;
  border: none;
}

.btn-secondary:hover {
  background-color: #00a79d;
  border: none;
}

.btn-custom {
  padding: 1rem 2rem;
  background-image: url(../images/btn-bg-orange.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.2rem;
  color: white;
}

.btn-custom-inverted {
  background-image: url(../images/btn-bg-purple.svg);
}

.btn-custom-aqua {
  background-image: url(../images/btn-bg-aqua.svg);
}

.btn-sm.btn-custom {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.track-border {
  width: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.track-border-grey {
  background-image: url(../images/border-grey.svg);
}

.track-border-white {
  background-image: url(../images/border-white.svg);
}

.under-form-text {
  display: block;
  margin: -0.75rem 0 1rem;
}

img {
  max-width: 100%;
}

img.img-lightbox {
  cursor: zoom-in;
}

.form-group label:empty {
  display: none;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Header Template Styles] */
.header {
  position: relative;
  z-index: 1030;
  padding: 1rem 0 1.5rem;
  background-color: #2b2e34;
}

.header .track-border {
  bottom: -12px;
  left: 0;
  height: 20px;
}

.header .h-logo-derby a, .header .h-logo-derby a:hover, .header .h-logo-derby a:focus {
  text-decoration: none;
}

.header .h-logo-derby img {
  max-height: 56px;
  max-width: 160px;
  width: auto;
  margin-right: 1rem;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-dark {
  padding: 0;
  margin: 0;
}

.navbar-dark .navbar-collapse {
  justify-content: flex-end;
}

.navbar-dark .navbar-collapse .nav-link {
  padding: 0.25rem 1rem;
  color: white;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.navbar-dark .navbar-collapse .nav-item:last-child .nav-link {
  padding-right: 0;
}

.navbar-dark .navbar-collapse .nav-link:hover,
.navbar-dark .navbar-collapse .nav-link:focus {
  color: #00a79d;
}

.navbar-dark .navbar-collapse .show > .nav-link,
.navbar-dark .navbar-collapse .active > .nav-link,
.navbar-dark .navbar-collapse .nav-link.show,
.navbar-dark .navbar-collapse .nav-link.active {
  color: #e46723;
}

.navbar-dark .navbar-collapse .nav-link.btn-custom {
  padding: 0.5rem 1rem;
  margin-top: -0.375rem;
  cursor: inherit;
}

.navbar-dark .navbar-collapse .nav-link.btn-custom span {
  color: #2b2e34;
}

.navbar-dark .navbar-collapse .nav-link.btn-custom:hover,
.navbar-dark .navbar-collapse .nav-link.btn-custom:focus {
  color: white;
}

.navbar-dark .navbar-toggler {
  width: 100%;
  margin-top: 1rem;
  padding: 0.5rem 0rem;
  color: white;
  border-color: white;
  font-size: 0.9rem;
}

/* Large desktop */
@media (min-width: 1200px) {
  .navbar-dark .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    font-size: 0.9rem;
  }
  .navbar-dark .dropdown-menu .dropdown-item.active,
  .navbar-dark .dropdown-menu .dropdown-item:active {
    background-color: #e46723;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-dark .navbar-collapse .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    font-size: 0.9rem;
  }
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item.active,
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item:active {
    background-color: #e46723;
  }
  .navbar-dark .navbar-collapse .nav-link {
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-dark {
    margin: 2rem 0 0 0;
  }
  .navbar-dark .navbar-collapse {
    justify-content: space-between;
  }
  .navbar-dark .navbar-collapse .nav-link.btn-custom {
    line-height: 1.2;
    padding: 0.75rem 1rem;
  }
  .navbar-dark .navbar-collapse .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    font-size: 0.9rem;
  }
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item.active,
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item:active {
    background-color: #e46723;
  }
  .navbar-dark .navbar-collapse .nav-link {
    padding: 0.25rem 0.75rem;
    font-size: 0.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-dark .navbar-collapse .dropdown-menu {
    border-radius: 0;
    font-size: 0.9rem;
  }
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item {
    text-align: center;
  }
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item.active,
  .navbar-dark .navbar-collapse .dropdown-menu .dropdown-item:active {
    background-color: #e46723;
  }
  .navbar-dark .navbar-collapse .nav-link {
    padding: 0.5rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    text-align: center;
  }
  .navbar-dark .navbar-collapse .nav-item:last-child .nav-link {
    border-left: none;
  }
  .navbar-dark .navbar-collapse.navbar-derby .nav-link {
    padding: 0.25rem 0;
    border: none;
  }
  .navbar-dark .navbar-collapse.navbar-derby .nav-link.btn-custom {
    padding: 0.5rem 1rem;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 1rem 0 3rem 0;
}

.page .nav:not(.nav-tabs) h5 {
  margin: 0 0 1rem 0;
  text-transform: uppercase;
}

.page .nav:not(.nav-tabs) .nav-link {
  padding: 0.5rem 0;
  color: #2b2e34;
}

.page .nav:not(.nav-tabs) .nav-item.active .nav-link {
  color: #e46723;
}

.page .breadcrumb {
  padding: 0;
  margin: 0 0 2rem 0;
  background-color: transparent;
  opacity: 0.7;
  font-size: 0.8rem;
}

.page .breadcrumb a {
  color: #2b2e34;
}

.subpage-banner {
  width: 100%;
  padding: 6rem 2rem;
  position: relative;
  background-size: cover;
  text-align: center;
}

.subpage-banner h1 {
  margin: 0;
  position: relative;
  z-index: 990;
  text-transform: uppercase;
  font-size: 3.2rem;
}

.subpage-banner .track-border {
  bottom: -12px;
  left: 0;
  height: 20px;
  z-index: 1000;
}

.subpage-banner::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.6);
}

.rally-banner {
  position: relative;
  padding: 3rem 2rem 10rem 2rem;
  z-index: 1;
}

.rally-banner .container {
  position: relative;
  z-index: 1000;
}

.rally-banner h1 {
  position: absolute;
  width: 100%;
  top: 60%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  color: white;
}

.rally-banner .navbar-nav .nav-link {
  color: white;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Francois One", sans-serif;
}

.rally-banner .navbar-nav .nav-link.active {
  color: #e46723;
}

.rally-banner .navbar-nav .nav-link:hover {
  color: #00a79d;
}

.rally-banner .btn {
  color: white;
}

.rally-banner .btn:hover {
  color: #2b2e34;
}

.rally-banner::before {
  background-color: rgba(0, 0, 0, 0.7);
}

.homepage-statement {
  width: 100%;
  position: relative;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e46723;
  color: white;
  text-align: center;
}

.homepage-statement h2 {
  margin: 0;
  color: white;
  font-size: 3rem;
}

.homepage-statement .track-border {
  top: -8px;
  left: 0;
  height: 20px;
}

.swiper-container {
  width: 100%;
  height: 800px !important;
}

.swiper-container .rally-banner {
  position: absolute;
  width: 100%;
  top: 0;
  padding-bottom: 3rem;
  text-align: center;
}

.swiper-container .rally-banner.rally-banner-center {
  top: 50%;
  transform: translate(0, -50%);
  min-height: auto;
}

.swiper-container .swiper-wrapper {
  z-index: 1;
}

.swiper-container .swiper-slide-image {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
}

.swiper-container .swiper-slide-image::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.swiper-container .swiper-text {
  position: absolute;
  bottom: 30%;
  width: 100%;
  left: 0;
  padding: 0 2rem;
  color: white;
  font-size: 4rem;
  text-align: center;
  font-weight: 700;
}

.gallery-hover {
  position: relative;
}

.gallery-link {
  position: relative;
  width: 100%;
  height: 100%;
}

.major-sponsors {
  padding: 3rem;
  box-shadow: 0px -6px 9px 0px rgba(50, 50, 50, 0.2);
}

.major-sponsors h4 {
  text-align: center;
  padding: .5rem 1rem;
  margin: 0;
  font-size: 2rem;
}

.major-sponsors .major-sponsors-categories > div.col {
  flex: 0 0 100%;
  margin: 2rem 0;
}

.major-sponsors .major-sponsors-categories > div.col .major-sponsors-container {
  padding: 1rem 2rem;
}

.major-sponsors .major-sponsors-categories > div.col .major-sponsors-container > div.col {
  text-align: center;
  margin: 1rem 0;
}

.major-sponsors .major-sponsors-categories > div.col:first-of-type {
  position: relative;
  flex: 0 0 37.5%;
  background-color: white;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.major-sponsors .major-sponsors-categories > div.col:first-of-type h4 {
  text-transform: uppercase;
  color: white;
  background-color: #2b2e34;
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(2) {
  flex: 0 0 62.5%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(2) h4 {
  text-transform: uppercase;
  color: white;
  background-color: #737058;
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(3),
.major-sponsors .major-sponsors-categories > div.col:last-of-type {
  box-shadow: none;
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(3) h4,
.major-sponsors .major-sponsors-categories > div.col:last-of-type h4 {
  text-transform: none;
  color: #2b2e34;
  background-color: transparent;
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(n+3) .major-sponsors-container {
  padding: 1rem 0;
}

.major-sponsors .major-sponsors-categories > div.col:nth-of-type(n+3) .major-sponsors-container > div.col {
  flex: 0 0 12.5%;
}

.listing {
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;
  border: 1px solid #2b2e34;
  font-size: 0.8rem;
}

.listing.event-details {
  height: auto;
}

.listing .listing-content {
  padding: 1.5rem 1.5rem 1.0rem 1.5rem;
}

.listing .listing-content small {
  color: rgba(43, 46, 52, 0.5);
  font-size: 1rem;
}

.listing .listing-content small strong {
  color: #e46723;
}

.listing .listing-content h3 {
  margin: 0 0 1rem 0;
  font-size: 1.4rem;
}

.listing .listing-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.listing .listing-actions .btn {
  padding: 0.75rem 1.5rem;
}

.listing .listing-actions .btn.btn-donate {
  padding: 0.75rem 0;
}

.listing .listing-actions .fa {
  color: #e46723;
}

.listing-event {
  height: auto;
}

.search {
  border: 1px solid #2b2e34;
}

.search .form-group {
  margin: 0;
}

.search label {
  font-family: "Francois One", sans-serif;
  font-size: 1.3rem;
}

.search .form-control {
  padding: 0;
  border: none;
  font-size: 0.9rem;
}

.search .btn {
  background-color: transparent;
  font-size: 1.6rem;
}

.progress {
  width: 100%;
  height: 25px;
}

.progress .progress-bar {
  background-color: #e46723;
}

.page-homepage {
  padding: 3rem 0;
  position: relative;
}

.page-homepage .track-border {
  top: -8px;
  left: 0;
  height: 20px;
}

.image-border {
  position: relative;
}

.image-border::before {
  position: absolute;
  content: ' ';
  top: 0;
  left: -1px;
  width: 101%;
  height: 101%;
  background-image: url(../images/image-border.svg);
  background-size: cover;
  background-repeat: no-repeat;
}

.feature-nav {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Francois One", sans-serif;
  color: white;
  font-size: 1.4rem;
}

.feature-nav .track-border {
  top: -8px;
  left: 0;
  height: 20px;
}

.feature-nav .item {
  flex-basis: 25%;
  height: 320px;
}

.feature-nav .item a {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.feature-nav .item a .title {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 1.5rem 2rem;
}

.team-donations {
  position: relative;
}

.team-donations:before {
  content: ' ';
  position: absolute;
  left: 50%;
  height: 100%;
  border-left: 1px solid black;
}

.team-donations .donations-col .donation-listing .donation-details {
  font-weight: bold;
}

.team-donations .donations-col .donation-listing:nth-child(2n - 1) {
  background: #ededed;
}

.registration-embed {
  height: 1170px;
  width: 100%;
}

/* Large desktop */
@media (min-width: 1200px) {
  .rally-banner {
    height: 60vh;
    min-height: 450px;
  }
  .registration-embed {
    height: 1070px;
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .rally-banner {
    height: 60vh;
    min-height: 450px;
  }
  .rally-banner .nav .nav-link {
    padding: 0.5rem 0.75rem;
  }
  .feature-nav .item {
    flex-basis: 25%;
    height: 240px;
  }
  .major-sponsors .major-sponsors-categories > div.col:nth-of-type(n+3) .major-sponsors-container > div.col {
    flex: 0 0 16.66%;
  }
  .swiper-container {
    height: 600px !important;
  }
  .swiper-container .swiper-text {
    bottom: 20%;
    padding: 0 2rem;
    font-size: 3rem;
  }
  .homepage-statement {
    padding: 1.4rem;
  }
  .homepage-statement h2 {
    font-size: 2.2rem;
  }
  .registration-embed {
    height: 1090px;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .subpage-banner {
    padding: 4rem 2rem;
  }
  .subpage-banner h1 {
    font-size: 2.4rem;
  }
  .rally-banner .nav .nav-link {
    color: white;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: "Francois One", sans-serif;
  }
  .rally-banner h1 {
    position: relative;
    margin-top: 3rem;
  }
  .page .nav h5 {
    margin: 0 0 0.75rem 0;
  }
  .page .nav .nav-link {
    font-size: 0.9rem;
  }
  .major-sponsors .major-sponsors-categories > div.col:nth-of-type(n+3) .major-sponsors-container > div.col {
    flex: 0 0 16.66%;
  }
  .feature-nav .item {
    flex-basis: 50%;
    height: 240px;
  }
  .listing {
    padding-bottom: 6rem;
  }
  .swiper-container {
    height: 500px !important;
  }
  .swiper-container .swiper-text {
    bottom: 3rem;
    padding: 0 1rem;
    font-size: 2rem;
    line-height: 2rem;
  }
  .homepage-statement {
    padding: 1.4rem;
  }
  .homepage-statement h2 {
    font-size: 2.2rem;
  }
  .registration-embed {
    height: 1090px;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .subpage-banner {
    padding: 4rem 2rem;
  }
  .subpage-banner h1 {
    font-size: 2.4rem;
  }
  .rally-banner h1 {
    margin-top: 2rem;
    position: relative;
    transform: translateY(0);
  }
  .page {
    padding: 2rem 0.5rem;
  }
  .page .nav {
    padding: 1rem 0;
    margin-top: 1rem;
    background-color: rgba(43, 46, 52, 0.07);
    text-align: center;
  }
  .major-sponsors {
    padding: 2rem 1.5rem;
  }
  .major-sponsors .major-sponsors-categories > div.col {
    flex: 0 0 100% !important;
  }
  .major-sponsors .major-sponsors-categories > div.col:nth-of-type(n+3) .major-sponsors-container > div.col {
    flex: 0 0 33.33%;
  }
  .listing {
    padding-bottom: 0;
  }
  .listing .listing-actions {
    position: static;
  }
  .feature-nav {
    display: block;
    font-size: 1.2rem;
  }
  .feature-nav .item {
    width: 100%;
    flex-basis: 100%;
    height: 240px;
  }
  .feature-nav .item a .title {
    padding: 1.0rem 1rem;
  }
  .swiper-container {
    height: 500px !important;
  }
  .swiper-container .swiper-text {
    padding: 0 1rem;
    bottom: auto;
    top: 340px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .homepage-statement {
    padding: 1rem;
  }
  .homepage-statement h2 {
    font-size: 1.4rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (max-width: 575px) {
  .swiper-container.swiper-container-rally {
    height: auto !important;
  }
  .swiper-container.swiper-container-rally .swiper-wrapper {
    position: absolute;
  }
  .swiper-container.swiper-container-rally .rally-banner {
    position: static;
    transform: none;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  position: relative;
  padding: 2.5rem 0 3.5rem 0;
  background-color: #2b2e34;
  color: white;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.footer .track-border {
  top: -8px;
  left: 0;
  height: 20px;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #e46723;
}

.footer .social {
  display: inline-block;
}

.footer .social a {
  display: inline-block;
  margin: 0 0.25rem;
}

.footer .nav-link {
  padding: 0 1rem;
}

.footer .nav-link:first-child {
  padding-left: 0;
}

.footer .nav-link:last-child {
  padding-right: 0;
}

.footer .nav.flex-column {
  margin-bottom: 0.5rem;
}

.footer .nav.flex-column .nav-link {
  padding: 0;
  margin-bottom: 0.5rem;
}

.footer .footnotes {
  opacity: 0.4;
}

.footer .btn-custom:hover {
  color: #2b2e34;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .footer {
    font-size: 0.7rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .footer {
    text-align: center;
  }
  .footer .nav {
    justify-content: center;
  }
  .footer .nav.flex-column {
    font-size: 0.7rem;
    text-align: left;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
    font-size: 0.8rem;
  }
  .footer .nav {
    display: block;
    margin: 1.5rem 0;
  }
  .footer .nav .nav-link {
    padding: 0.5rem 0;
    border: none;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
