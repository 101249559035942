/*==========================================================================

	Template Elements Stylesheet
	____________________________________________________________

	Client:		Rally for a Cause
	Date:		31st October 2018
	Author:		Edge Marketing Solutions

===========================================================================*/

	$color-black: rgb(0, 0, 0);
	$color-grey-dark: rgb(43, 46, 52);
	$color-orange: rgb(228, 103, 35);
	$color-white: rgb(255, 255, 255);
	$color-green: rgb(0, 167, 157);
	$color-purple: rgb(167, 101, 169);

	$font-default: 'Open Sans', sans-serif;
	$font-featured: 'Francois One', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey-dark;

		font-family: $font-default;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6
	{
		margin: 2rem 0;
		font-family: $font-featured;
	}

	h1
	{
		margin: 0 0 2rem 0;

		color: $color-grey-dark;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-orange;
	}

		a:hover
		{
			color: $color-green;
		}

	.text-orange {
		color: $color-orange;
	}

	.btn
	{
		border-radius: 0;

		font-family: $font-featured;
		white-space: normal;
	}

	.btn-primary
	{
		background-color: $color-orange;
		border: none;
	}

		.btn-primary:hover
		{
			background-color: $color-green;
			border: none;
		}

	.btn-secondary
	{
		background-color: $color-grey-dark;
		border: none;
	}

		.btn-secondary:hover
		{
			background-color: $color-green;
			border: none;
		}

	.btn-custom
	{
		padding: 1rem 2rem;

		background-image: url(../images/btn-bg-orange.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;

		font-size: 1.2rem;
		color: $color-white;
	}

	.btn-custom-inverted
	{
		background-image: url(../images/btn-bg-purple.svg);
	}

	.btn-custom-aqua
	{
		background-image: url(../images/btn-bg-aqua.svg);
	}

	.btn-sm.btn-custom
	{
		padding: 0.5rem 1rem;
		font-size: 1rem;
	}

	.track-border
	{
		width: 100%;
		position: absolute;

		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

		.track-border-grey
		{
			background-image: url(../images/border-grey.svg);
		}

		.track-border-white
		{
			background-image: url(../images/border-white.svg);
		}

	.under-form-text {
		display: block;
		margin: -0.75rem 0 1rem;
	}

	img
	{
		max-width: 100%;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	.form-group label:empty {
		display: none;
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		position: relative;
		z-index: 1030;
		padding: 1rem 0 1.5rem;

		background-color: $color-grey-dark;


		.track-border
		{
			bottom: -12px;
			left: 0;
			height: 20px;
		}

		.h-logo-derby {
			a, a:hover, a:focus {
				text-decoration: none;
			}
			img {
				max-height: 56px;
				max-width: 160px;
				width: auto;
				margin-right: 1rem;
			}
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-dark
	{
		padding: 0;
		margin: 0;

		.navbar-collapse
		{
			justify-content: flex-end;

			.nav-link
			{
				padding: 0.25rem 1rem;

				color: $color-white;
				font-size: 0.9rem;
				text-transform: uppercase;
			}

			.nav-item:last-child .nav-link
			{
				padding-right: 0;

				//border-left: 1px solid $color-white;
			}

			.nav-link:hover,
			.nav-link:focus
			{
				color: $color-green;
			}

			.show > .nav-link,
			.active > .nav-link,
			.nav-link.show,
			.nav-link.active
			{
				color: $color-orange;
			}

			.nav-link.btn-custom
			{
				padding: 0.5rem 1rem;
				margin-top: -0.375rem;
				cursor: inherit;

				span {
					color: $color-grey-dark;
				}
			}

			.nav-link.btn-custom:hover,
			.nav-link.btn-custom:focus
			{
				color: $color-white;
			}
		}

		.navbar-toggler
		{
			width: 100%;
			margin-top: 1rem;
			padding: 0.5rem 0rem;

			color: $color-white;
			border-color: $color-white;
			font-size: 0.9rem;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {
		.navbar-dark
		{
			.dropdown-menu
			{
				left: 50%;
				transform: translateX(-50%);

				border-radius: 0;

				font-size: 0.9rem;

				.dropdown-item.active,
				.dropdown-item:active
				{
					background-color: $color-orange;
				}
			}
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-dark
		{
			.navbar-collapse
			{
				.dropdown-menu
				{
					left: 50%;
					transform: translateX(-50%);

					border-radius: 0;

					font-size: 0.9rem;

					.dropdown-item.active,
					.dropdown-item:active
					{
						background-color: $color-orange;
					}
				}

				.nav-link
				{
					padding: 0.25rem 0.75rem;

					font-size: 0.8rem;
				}
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-dark
		{
			margin: 2rem 0 0 0;

			.navbar-collapse
			{
				justify-content: space-between;

				.nav-link.btn-custom
				{
					line-height: 1.2;
					padding: 0.75rem 1rem;
				}

				.dropdown-menu
				{
					left: 50%;
					transform: translateX(-50%);

					border-radius: 0;

					font-size: 0.9rem;

					.dropdown-item.active,
					.dropdown-item:active
					{
						background-color: $color-orange;
					}
				}

				.nav-link
				{
					padding: 0.25rem 0.75rem;

					font-size: 0.8rem;
				}
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-dark
		{
			.navbar-collapse
			{

				.dropdown-menu
				{
					border-radius: 0;

					font-size: 0.9rem;

					.dropdown-item
					{
						text-align: center;
					}

					.dropdown-item.active,
					.dropdown-item:active
					{
						background-color: $color-orange;
					}
				}

				.nav-link
				{
					padding: 0.5rem 0rem;
					border-bottom: 1px solid transparentize($color-white, 0.9);

					text-align: center;
				}

				.nav-item:last-child .nav-link
				{
					border-left: none;
				}

				&.navbar-derby {

					.nav-link {
						padding: 0.25rem 0;
						border: none;
					}

					.nav-link.btn-custom {
						padding: 0.5rem 1rem;
					}
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 1rem 0 3rem 0;

		.nav:not(.nav-tabs)
		{
			h5
			{
				margin: 0 0 1rem 0;

				text-transform: uppercase;
			}

			.nav-link
			{
				padding: 0.5rem 0;

				color: $color-grey-dark;
			}

			.nav-item.active .nav-link
			{
				color: $color-orange;
			}
		}

		.breadcrumb
		{
			padding: 0;
			margin: 0 0 2rem 0;

			background-color: transparent;
			opacity: 0.7;

			font-size: 0.8rem;

			a
			{
				color: $color-grey-dark;
			}
		}
	}

	.subpage-banner
	{
		width: 100%;
		padding: 6rem 2rem;
		position: relative;

		background-size: cover;

		text-align: center;

		h1
		{
			margin: 0;
			position: relative;
			z-index: 990;

			text-transform: uppercase;
			font-size: 3.2rem;
		}

		.track-border
		{
			bottom: -12px;
			left: 0;
			height: 20px;
			z-index: 1000;
		}
	}

		.subpage-banner::before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			background-color: transparentize($color-white, 0.4);
		}

	.rally-banner
	{
		position: relative;
		padding: 3rem 2rem 10rem 2rem;
		z-index: 1;

		.container
		{
			position: relative;
			z-index: 1000;
		}

		h1
		{
			position: absolute;
			width: 100%;
			top: 60%;
			left: 0;
			transform: translateY(-50%);

			text-align: center;
			color: $color-white;
		}

		.navbar-nav
		{
			.nav-link
			{
				color: $color-white;
				text-transform: uppercase;
				font-size: 1rem;
				font-family: $font-featured;
			}

			.nav-link.active
			{
				color: $color-orange;
			}

			.nav-link:hover
			{
				color: $color-green;
			}
		}

		.btn
		{
			color: $color-white;
		}

		.btn:hover
		{
			color: $color-grey-dark;
		}
	}

		.rally-banner::before
		{
			background-color: transparentize($color-black, 0.3);
		}

	.homepage-statement
	{
		width: 100%;
		position: relative;
		padding: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;

		background-color: $color-orange;

		color: $color-white;
		text-align: center;

		h2
		{
			margin: 0;

			color: $color-white;
			font-size: 3rem;
		}

		.track-border
		{
			top: -8px;
			left: 0;
			height: 20px;
		}
	}

	.swiper-container
	{
		width: 100%;
		height: 800px !important;

		.rally-banner
		{
			position: absolute;
			width: 100%;
			top: 0;
			padding-bottom: 3rem;

			text-align: center;

			&.rally-banner-center {
				top: 50%;
				transform: translate(0, -50%);
				min-height: auto;
			}
		}

		.swiper-wrapper
		{
			z-index: 1;
		}

		.swiper-slide-image
		{
			width: 100%;
			height: 100%;
			position: relative;

			background-size: cover;
		}

			.swiper-slide-image::before
			{
				content: ' ';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				background-color: transparentize($color-black, 0.6);
			}

		.swiper-text
		{
			position: absolute;
			bottom: 30%;
			width: 100%;
			left: 0;
			padding: 0 2rem;

			color: $color-white;
			font-size: 4rem;
			text-align: center;
			font-weight: 700;
		}
	}

	.gallery-hover
	{
		position: relative;
	}

	.gallery-link
	{
		position: relative;
		width: 100%;
		height: 100%;
	}

	.major-sponsors {
		padding: 3rem;

		box-shadow: 0px -6px 9px 0px rgba(50, 50, 50, 0.2);

		h4 {
			text-align: center;
			padding: .5rem 1rem;
			margin: 0;

			font-size: 2rem;
		}

		.major-sponsors-categories > div.col {
			flex: 0 0 100%;
			margin: 2rem 0;

			.major-sponsors-container {
				padding: 1rem 2rem;
				> div.col {
					text-align: center;
					margin: 1rem 0;
				}
			}
		}

		.major-sponsors-categories > div.col:first-of-type {
			position: relative;
			flex: 0 0 37.5%;
			background-color: $color-white;
			box-shadow: 0 0 30px rgba(0, 0, 0, 0.25);
			z-index: 1;

			h4 {
				text-transform: uppercase;
				color: $color-white;
				background-color: $color-grey-dark;
			}
		}

		.major-sponsors-categories > div.col:nth-of-type(2) {
			flex: 0 0 62.5%;
			background-color: $color-white;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

			h4 {
				text-transform: uppercase;
				color: $color-white;
				background-color: #737058;
			}
		}

		.major-sponsors-categories > div.col:nth-of-type(3),
		.major-sponsors-categories > div.col:last-of-type {
			box-shadow: none;

			h4 {
				text-transform: none;
				color: $color-grey-dark;
				background-color: transparent;
			}
		}

		.major-sponsors-categories > div.col:nth-of-type(n+3) {
			.major-sponsors-container {
				padding: 1rem 0;
				> div.col {
					flex: 0 0 12.5%;
				}
			}
		}
	}

	.listing
	{
		position: relative;
		width: 100%;
		height: 100%;
		padding-bottom: 3rem;

		border: 1px solid $color-grey-dark;

		font-size: 0.8rem;

		&.event-details {
			height: auto;
		}

		.listing-content
		{
			padding: 1.5rem 1.5rem 1.0rem 1.5rem;

			small
			{
				color: transparentize($color-grey-dark, 0.5);
				font-size: 1rem;

				strong
				{
					color: $color-orange;
				}
			}

			h3
			{
				margin: 0 0 1rem 0;
				font-size: 1.4rem;
			}
		}

		.listing-actions
		{
			position: absolute;
			width: 100%;
			bottom: 0;
			left: 0;

			.btn
			{
				padding: 0.75rem 1.5rem;
			}

			.btn.btn-donate
			{
				padding: 0.75rem 0;
			}

			.fa
			{
				color: $color-orange;
			}
		}
	}

	.listing-event
	{
		height: auto;
	}

	.search
	{
		border: 1px solid $color-grey-dark;

		.form-group
		{
			margin: 0;
		}

		label
		{
			font-family: $font-featured;
			font-size: 1.3rem;
		}

		.form-control
		{
			padding: 0;

			border: none;

			font-size: 0.9rem;
		}

		.btn
		{
			background-color: transparent;
			font-size: 1.6rem;
		}
	}

	.progress
	{
		width: 100%;
		height: 25px;

		.progress-bar
		{
			background-color: $color-orange;
		}
	}

	.page-homepage
	{
		padding: 3rem 0;
		position: relative;

		.track-border
		{
			top: -8px;
			left: 0;
			height: 20px;
		}
	}

		.image-border
		{
			position: relative;
		}

			.image-border::before
			{
				position: absolute;
				content: ' ';
				top: 0;
				left: -1px;
				width: 101%;
				height: 101%;

				background-image: url(../images/image-border.svg);
				background-size: cover;
				background-repeat: no-repeat;
			}

	.feature-nav
	{
		width: 100%;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		font-family: $font-featured;
		color: $color-white;
		font-size: 1.4rem;

		.track-border
		{
			top: -8px;
			left: 0;
			height: 20px;
		}

		.item
		{
			flex-basis: 25%;
			height: 320px;

			a
			{
				position: relative;
				width: 100%;
				height: 100%;
				display: block;

				background-size: cover;
				background-repeat: no-repeat;

				color: $color-white;

				.title
				{
					position: absolute;
					width: 100%;
					bottom: 0;
					left: 0;
					padding: 1.5rem 2rem;
				}
			}
		}
	}

	.team-donations {
		position: relative;

		&:before {
			content: ' ';
			position: absolute;
			left: 50%;
			height: 100%;
			border-left: 1px solid black;
		}

		.donations-col {
			.donation-listing {
				.donation-details {
					font-weight: bold;
				}

				&:nth-child(2n - 1) {
					background: #ededed;
				}
			}
		}
	}

    .registration-embed {
        height: 1170px;
        width: 100%;
    }

	/* Large desktop */
	@media (min-width: 1200px) {
		.rally-banner
		{
			height: 60vh;
			min-height: 450px;
		}

        .registration-embed {
            height: 1070px;
        }
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px)
	{
		.rally-banner
		{
			height: 60vh;
			min-height: 450px;

			.nav
			{
				.nav-link
				{
					padding: 0.5rem 0.75rem;
				}
			}
		}

		.feature-nav
		{
			.item
			{
				flex-basis: 25%;
				height: 240px;
			}
		}

		.major-sponsors {
			.major-sponsors-categories > div.col:nth-of-type(n+3) {
				.major-sponsors-container {
					> div.col {
						flex: 0 0 16.66%;
					}
				}
			}
		}

		.swiper-container
		{
			height: 600px !important;

			.swiper-text
			{
				bottom: 20%;
				padding: 0 2rem;

				font-size: 3rem;
			}
		}

		.homepage-statement
		{
			padding: 1.4rem;

			h2
			{
				font-size: 2.2rem;
			}
		}

        .registration-embed {
            height: 1090px;
        }
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.subpage-banner
		{
			padding: 4rem 2rem;

			h1
			{
				font-size: 2.4rem;
			}
		}

		.rally-banner
		{
			.nav
			{
				.nav-link
				{
					color: $color-white;
					text-transform: uppercase;
					font-size: 1rem;
					font-family: $font-featured;
				}
			}

			h1
			{
				position: relative;
				margin-top: 3rem;
			}
		}

		.page
		{
			.nav
			{
				h5
				{
					margin: 0 0 0.75rem 0;
				}

				.nav-link
				{
					font-size: 0.9rem;
				}
			}
		}

		.major-sponsors {
			.major-sponsors-categories > div.col:nth-of-type(n+3) {
				.major-sponsors-container {
					> div.col {
						flex: 0 0 16.66%;
					}
				}
			}
		}

		.feature-nav
		{
			.item
			{
				flex-basis: 50%;
				height: 240px;
			}
		}

		.listing
		{
			padding-bottom: 6rem;
		}

		.swiper-container
		{
			height: 500px !important;

			.swiper-text
			{
				bottom: 3rem;
				padding: 0 1rem;

				font-size: 2rem;
				line-height: 2rem;
			}
		}

		.homepage-statement
		{
			padding: 1.4rem;

			h2
			{
				font-size: 2.2rem;
			}
		}

        .registration-embed {
            height: 1090px;
        }
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.subpage-banner
		{
			padding: 4rem 2rem;

			h1
			{
				font-size: 2.4rem;
			}
		}

		.rally-banner
		{
			.nav
			{
				.nav-link {


					.nav-link
					{

					}
				}
			}

			h1
			{
				margin-top: 2rem;
				position: relative;
				transform: translateY(0);
			}
		}

		.page
		{
			padding: 2rem 0.5rem;

			.nav
			{
				padding: 1rem 0;
				margin-top: 1rem;

				background-color: transparentize($color-grey-dark, 0.93);

				text-align: center;
			}
		}

		.major-sponsors
		{
			padding: 2rem 1.5rem;

			.major-sponsors-categories > div.col {
				flex: 0 0 100% !important;
			}

			.major-sponsors-categories > div.col:nth-of-type(n+3) {
				.major-sponsors-container {
					> div.col {
						flex: 0 0 33.33%;
					}
				}
			}
		}

		.listing
		{
			padding-bottom: 0;

			.listing-actions
			{
				position: static;
			}
		}

		.feature-nav
		{
			display: block;

			font-size: 1.2rem;

			.item
			{
				width: 100%;
				flex-basis: 100%;
				height: 240px;

				a
				{
					.title
					{
						padding: 1.0rem 1rem;
					}
				}
			}
		}

		.swiper-container
		{
			height: 500px !important;

			.swiper-text
			{
				padding: 0 1rem;
				bottom: auto;
				top: 340px;

				-webkit-box-sizing: border-box;
				-moz-box-sizing: border-box;
				box-sizing: border-box;

				font-size: 1.8rem;
				line-height: 2rem;
			}
		}

		.homepage-statement
		{
			padding: 1rem;

			h2
			{
				font-size: 1.4rem;
			}
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (max-width: 575px) {

		.swiper-container {
			&.swiper-container-rally {
				height: auto !important;

				.swiper-wrapper {
					position: absolute;
				}

				.rally-banner {
					position: static;
					transform: none;
				}
			}
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer
	{
		width: 100%;
		position: relative;
		padding: 2.5rem 0 3.5rem 0;

		background-color: $color-grey-dark;

		color: $color-white;
		text-transform: uppercase;
		font-size: 0.8rem;

		.track-border
		{
			top: -8px;
			left: 0;
			height: 20px;
		}

		a
		{
			color: $color-white;
		}

			a:hover
			{
				color: $color-orange;
			}

		.social
		{
			display: inline-block;

			a
			{
				display: inline-block;
				margin: 0 0.25rem;
			}
		}

		.nav-link
		{
			padding: 0 1rem;
		}

			.nav-link:first-child
			{
				padding-left: 0;
			}

			.nav-link:last-child
			{
				padding-right: 0;
			}

		.nav.flex-column {
			margin-bottom: 0.5rem;

			.nav-link
			{
				padding: 0;
				margin-bottom: 0.5rem;
			}
		}

		.footnotes
		{
			opacity: 0.4;
		}

		.btn-custom:hover
		{
			color: $color-grey-dark;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.footer
		{
			font-size: 0.7rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.footer
		{
			text-align: center;

			.nav
			{
				justify-content: center;
			}

			.nav.flex-column
			{
				font-size: 0.7rem;
				text-align: left;
			}
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer
		{
			text-align: center;
			font-size: 0.8rem;

			.nav
			{
				display: block;
				margin: 1.5rem 0;

				.nav-link
				{
					padding: 0.5rem 0;

					border: none;
				}


			}
		}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}
